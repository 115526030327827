import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  language: 'UA'
}

export const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeLanguage: (state, { payload }) => {
      return {
        language: payload
      }
    }
  }
})

export const { changeLanguage } = appReducer.actions

export default appReducer.reducer
