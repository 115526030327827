import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'i18n/config'

import './style.css'

import { store, persistor } from 'store'
import Router from 'router'
import Localization from 'components/Localization'
import ArrowTopSvg from 'assets/svg/ArrowTopSvg'

const App = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible)
  }, [visible])

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 1000) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Localization>
          <Router />
          {visible ? (
            <div className="arrowTopBtn" onClick={handleScrollTop}>
              <ArrowTopSvg />
            </div>
          ) : null}
        </Localization>
      </PersistGate>
    </Provider>
  )
}

export default App
