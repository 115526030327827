export default {
  header: {
    greatChanges: 'Great Changes',
    publicOrganization: 'Public Organization',
    makeDonation: 'Make a donation',
    requestForHelp: 'Request for help',
    home: 'home',
    aboutUs: 'about us',
    partners: 'partners',
    news: 'news',
    projects: 'projects',
    contacts: 'contacts'
  },
  home: {
    title: {
      first: 'We are creating a better ',
      secong: 'future ',
      third: 'for the people of our country. People are the ',
      fourth: 'most valuable, ',
      fifth: 'asset we have'
    },
    aboutUs: {
      title: {
        first: 'about',
        second: 'us'
      },
      slide1:
        'Events after February 24, 2022 became a real challenge for us. In the first days of the full-scale war in Ukraine, we began evacuating people, distributing bread and buying fuel for volunteers',
      slide2: {
        first: 'We are currently working in several directions.',
        second: 'Restoration of housing',
        third: 'Food program',
        fourth: 'Cultural revival'
      },
      team: 'Team “Great Changes”',
      founder: 'founder',
      director: 'director',
      manager: 'Manager',
      smmManager: 'Smm manager'
    },
    partners: {
      title: 'partners'
    },
    statistics: {
      first: 'citizens received home assistance',
      second: 'aid distributed (kg)',
      third: 'people evacuated',
      fourth: 'fuel for volunteers procured'
    },
    requisites: {
      title: 'BANK DETAILS',
      qrCode: {
        first: 'Download the Privat24 app at pb.ua/apps',
        second: 'Log in to the app and press',
        third: 'Scan the QR-code',
        fourth: 'Choose the card for payment'
      },
      privatBankCard: 'PrivatBank Card',
      publicOrganization: `Public organization "great Changes"`,
      purposePayment: 'Purpose of payment: Charity contribution.',
      btnHelpOnline: 'Help online',
      currentAccount: 'Current account in ',
      btnLearnMore: 'Learn more'
    },
    news: {
      title: 'News',
      readMore: 'Read more'
    },
    projects: {
      title: 'projects',
      form: {
        requestHelp: 'request for help',
        surnameAndName: 'Your surname and name',
        phone: 'your phone number',
        message: 'your message',
        personalData: 'I give consent to the processing of my personal data',
        privacyPolicy: 'Privacy Policy',
        send: 'send',
        successfully: 'Help request sent successfully',
        error: 'An error has occurred. Try later'
      }
    }
  },
  footer: {
    description: "If you sow good on your life's path, it will surely return to you with good.",
    organization: '© 2023 "Great Changes" public organization.',
    communication: "We'll be in touch",
    address: 'Address: Kharkiv city, Seminarska street, building 46, apartment 506',
    phone: 'phone:'
  },
  requisitesUAH: {
    currentAccountUAH: 'Current account in UAH',
    nameRecipient: "Recipient's name:",
    greatChanges: 'po Great Changes',
    recipientCode: "Recipient's code:",
    recipientAccount: "Recipient's account number:",
    bankName: 'Bank name:',
    privatBank: 'PJSC "PRIVATBANK"'
  },
  requisitesUSDAndEUR: {
    title: 'BANK DETAILS',
    currentAccount: 'Current account in '
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    revisionEffective: '(The revision is effective from 05/17/2023)',
    start:
      'This Privacy Policy (hereinafter referred to as the "Policy") regulates relations in the field of protection of personal data of visitors to the website https://greatchanges.org.ua/ (hereinafter referred to as the "Website"), which belongs to the PUBLIC ORGANIZATION " BIG CHANGES" (EDRPOU code 43534770, location: 61064, Kharkiv, Seminarska St., building 46, apartment 506), registered in accordance with the current legislation of Ukraine (hereinafter referred to as the "Organization").',
    end: 'The user, using all products and websites of the Organization, has accepted the terms of this privacy policy of the Organization and takes into account that this privacy policy may change from time to time. Changes to the privacy policy are posted on this page.'
  }
}
