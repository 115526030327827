import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// Resources
import eng from '../languages/eng'
import ua from '../languages/ua'

const languages = ['eng', 'ua']

export const resources = {
  eng: { translation: eng },
  ua: { translation: ua }
}

// Init
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      lng: 'ua',
      fallbackLng: {
        // Enable fallbacks if you want `en-US` and `en-GB` to fallback to `en`
        'en-US': ['eng'],
        'uk-UA': ['ua'],
        default: ['ua']
      },
      debug: false,
      whitelist: languages,
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      initImmediate: false,
      react: {
        nsMode: 'default',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b']
      }
      // eslint-disable- next-line consistent-return
    },
    err => {
      if (err) {
        console.error('Error loading translation files', err)
        return err
      }
    }
  )

export default i18n
