import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  posts: [],
  projects: [],
  sliders: [],
  teams: [],
  statistics: [],
  partners: []
}

export const homeReducer = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setPosts: (state, { payload }) => {
      return {
        ...state,
        posts: payload
      }
    },
    setProjects: (state, { payload }) => {
      return {
        ...state,
        projects: payload
      }
    },
    setSliders: (state, { payload }) => {
      return {
        ...state,
        sliders: payload
      }
    },
    setTeams: (state, { payload }) => {
      return {
        ...state,
        teams: payload
      }
    },
    setStatistics: (state, { payload }) => {
      return {
        ...state,
        statistics: payload
      }
    },
    setPartners: (state, { payload }) => {
      return {
        ...state,
        partners: payload
      }
    }
  }
})

export const { setPosts, setProjects, setSliders, setTeams, setStatistics, setPartners } =
  homeReducer.actions

export default homeReducer.reducer
