import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useCurrentLanguage from 'containers/App/hooks/useLanguage'
import { languageStateSelector } from 'containers/App/store/reducers/selectors'

const Localization = ({ children }) => {
  const { i18n } = useTranslation()
  const language = useSelector(languageStateSelector)
  const { currentLang } = useCurrentLanguage()

  useEffect(() => {
    i18n.changeLanguage(currentLang)
  }, [language])

  return <>{children}</>
}

export default Localization
