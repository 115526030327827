import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appReducer from 'containers/App/store/reducers'
import homeReducer from 'pages/Home/store/reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app', 'home']
}

const createRootReducer = persistCombineReducers(persistConfig, {
  app: appReducer,
  home: homeReducer
})

export default createRootReducer
