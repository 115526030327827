import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

import { ROUTES } from 'constants/routes'
const HomePage = lazy(() => import('pages/Home'))
const RequisitesUAHPage = lazy(() => import('pages/Requisites/components/RequisitesUAH'))
const RequisitesUSDAndEURPage = lazy(() =>
  import('pages/Requisites/components/RequisitesUSDAndEUR')
)
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicy'))

const Router = () => {
  return (
    <Suspense fallback={''}>
      <Routes>
        <Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
        <Route path={ROUTES.REQUISITES.UAH} element={<RequisitesUAHPage />} />
        <Route path={ROUTES.REQUISITES.USD} element={<RequisitesUSDAndEURPage />} />
        <Route path={ROUTES.REQUISITES.EUR} element={<RequisitesUSDAndEURPage />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      </Routes>
    </Suspense>
  )
}

export default Router
