const HOME_PAGE = '/'
const REQUISITES = {
  UAH: '/requisites-uah',
  USD: '/requisites-usd',
  EUR: '/requisites-eur'
}
const PRIVACY_POLICY = '/privacy-policy'

export const ROUTES = {
  HOME_PAGE,
  REQUISITES,
  PRIVACY_POLICY
}
