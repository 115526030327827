import { useSelector } from 'react-redux'
import { languageStateSelector } from '../store/reducers/selectors'
import { LANGUAGES } from 'constants/languages'

const useCurrentLanguage = () => {
  const language = useSelector(languageStateSelector)

  return { currentLang: LANGUAGES[language] }
}

export default useCurrentLanguage
