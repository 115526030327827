export default {
  header: {
    greatChanges: 'Великі зміни',
    publicOrganization: 'Громадська організація',
    makeDonation: 'зробити внесок',
    requestForHelp: 'запит на допомогу',
    home: 'Головна',
    aboutUs: 'Про нас',
    partners: 'партнери',
    news: 'новини',
    projects: 'проєкти',
    contacts: 'зв’язок'
  },
  home: {
    title: {
      first: 'Створюємо Краще ',
      secong: 'майбутнє ',
      third: 'народу нашої країни. Люди - ',
      fourth: 'найцінніше, ',
      fifth: 'що у нас є'
    },
    aboutUs: {
      title: {
        first: 'про',
        second: 'нас'
      },
      slide1:
        'Справжнім викликом для нас стали події після 24 лютого 2022 року. В перші дні повномасштабної війни в україні, ми почали евакуювати людей, роздавати хліб та закуповувати паливо для волонтерів',
      slide2: {
        first: 'Зараз ми працюємо у кількох напрямках.',
        second: 'Відновлення житла',
        third: 'Продовольча програма',
        fourth: 'Культурне відродження'
      },
      team: 'Команда “великі зміни”',
      founder: 'засновник',
      director: 'Директор',
      manager: 'Менеджер',
      smmManager: 'Smm Менеджер'
    },
    partners: {
      title: 'партнери'
    },
    statistics: {
      first: 'грамодян отримали домопогу',
      second: 'роздано допомоги (кг)',
      third: 'Евакуйовано людей',
      fourth: 'палива для волонтерів'
    },
    requisites: {
      title: 'РЕКВІЗИТИ РАХУНКІВ',
      qrCode: {
        first: 'Завантажте додаток Приват24 на pb.ua/apps',
        second: 'Увійдіть у додаток та натисніть',
        third: 'Відскануйте QR-код',
        fourth: 'Оберіть картку для оплати'
      },
      privatBankCard: 'Карта банку ПриватБанк',
      publicOrganization: `Громадська ораганізація "Великі зміни"`,
      purposePayment: 'Призначення платежу: Благодійний внесок.',
      btnHelpOnline: 'Допомогти онлайн',
      currentAccount: 'Поточний рахунок в ',
      btnLearnMore: 'детальніше'
    },
    news: {
      title: 'Новини',
      readMore: 'Прочитати більше'
    },
    projects: {
      title: 'проєкти',
      form: {
        requestHelp: 'запит на допомогу',
        surnameAndName: 'ваше прізвище та ім’Я',
        phone: 'ваш номер телефону',
        message: 'ваше повідомлення',
        personalData: 'Я даю згоду на обробку моїх персональних даних',
        privacyPolicy: 'Політика конфіденційності',
        send: 'відправити',
        successfully: 'Запит на допомогу успішно надіслано',
        error: 'Сталася помилка. Спробуйте пізніше'
      }
    }
  },
  footer: {
    description:
      'Якщо добро ти сіятимеш на життєвому шляху, воно неодмінно тобі добром повернеться.',
    organization: '© 2023 "Великі зміни" громадська організація.',
    communication: "Будемо на зв'язку",
    address: 'Адреса: м. Харків, вул. Семінарська, буд. 46, кв. 506',
    phone: 'тел:'
  },
  requisitesUAH: {
    currentAccountUAH: 'Поточний рахунок в UAH',
    nameRecipient: 'Найменування отримувача:',
    greatChanges: 'ГО ВЕЛИКІ ЗМІНИ',
    recipientCode: 'Код отримувача:',
    recipientAccount: 'Рахунок отримувача:',
    bankName: 'Назва банку:',
    privatBank: 'АТ КБ ПРИВАТБАНК'
  },
  requisitesUSDAndEUR: {
    title: 'РЕКВІЗИТИ РАХУНКІВ',
    currentAccount: 'Поточний рахунок в '
  },
  privacyPolicy: {
    title: 'Політика конфіденційності',
    revisionEffective: '(Редакція чинна з 17.05.2023)',
    start:
      'Дана Політика конфіденційності (далі за текстом – «Політика») регулює відносини у сфері захисту персональних даних відвідувачів веб-сайту https://greatchanges.org.ua/ (далі за текстом – «Веб-сайт»), який належить ГРОМАДСЬКІЙ ОРГАНІЗАЦІЇ «ВЕЛИКІ ЗМІНИ» (код ЄДРПОУ 43534770, місцезнаходження: 61064, м. Харків, вул. Семінарська, буд. 46, кв. 506 ), зареєстрованої згідно чинного законодавства України (далі за текстом – «Організація»).',
    end: 'Користувач, використовуючи всі продукти і веб-сайти Організації, прийняв умови цієї політики конфіденційності Організація і враховує, що дана політика конфіденційності може час від часу змінюватися. Зміни, що вносяться до політики конфіденційності, публікуються на цій сторінці.'
  }
}
